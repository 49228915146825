import svg4everybody from 'svg4everybody';
import Swiper, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import initIntro from './initIntro';

if (module.hot) {
  module.hot.accept();
}

Swiper.use([Pagination]);

/* News on Home */

// eslint-disable-next-line no-unused-vars
const swiper = new Swiper('.swiper-container', {
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

/* Header Panels */

const header = document.getElementById('header');
const panels = document.querySelectorAll('[data-header-panel]');
const togglers = document.querySelectorAll('[data-header-toggle]');

const togglePanel = (name) => {
  if (header.dataset.headerOpened === name) {
    header.removeAttribute('data-header-opened');
    panels.forEach((el) => el.setAttribute('hidden', ''));
    return;
  }

  const targetScroll = header.getBoundingClientRect().top + window.scrollY;
  if (window.scrollY < targetScroll) {
    window.scrollTo(0, targetScroll);
  }
  header.setAttribute('data-header-opened', name);

  panels.forEach((el) => {
    if (el.dataset.headerPanel === name) {
      el.removeAttribute('hidden');
    } else {
      el.setAttribute('hidden', '');
    }
  });
};

togglers.forEach((toggle) => {
  toggle.addEventListener('click', () => togglePanel(toggle.dataset.headerToggle));
});

/* Home Parallax */

initIntro();

/* For SVG use icons */

svg4everybody();

/* Ajax Modal */

function showAjaxModal(id, action) {
  fetch('assets/template/action.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify({ action, id }),
  })
    .then((response) => response.json())
    .then((data) => {
      // $('#modal-ajax-content').html(response.data.html);
      console.log(data);
    });
}

const galleryOpenButtons = document.querySelectorAll('.js-gallery-open');
if (galleryOpenButtons.length > 0) {
  galleryOpenButtons.forEach((el) => {
    el.addEventListener('click', () => {
      showAjaxModal(el.dataset.id, 'gallery');
    });
  });
}

const resourceOpenButtons = document.querySelectorAll('.js-resource-open');
if (resourceOpenButtons.length > 0) {
  resourceOpenButtons.forEach((el) => {
    el.addEventListener('click', () => {
      showAjaxModal(el.dataset.id, 'resource');
    });
  });
}
