import Parallax from 'parallax-js';

function initIntro() {
  let introParallaxInstance;
  // const htmlElement = document.querySelector('html');
  // const bodyElement = document.querySelector('body');
  const introElement = document.querySelector('.intro');
  const introCoverElements = document.querySelectorAll('.intro-cover-element');
  const scene = document.getElementById('intro-parallax');
  const preloaderElement = document.getElementById('preloader');
  const preloaderLineElement = document.getElementById('preloader-line');

  const hidePreloader = () => {
    preloaderLineElement.style.transform = 'translateX(-50%) scaleX(1)';
    setTimeout(() => {
      preloaderElement.style.opacity = '0';
      preloaderElement.style.visibility = 'hidden';
      setTimeout(() => {
        // htmlElement.style.overflow = 'auto';
        // bodyElement.style.overflow = 'auto';
        introElement.classList.add('intro_loaded');
      }, 200);
    }, 500);
  };

  const introCoverElementsSizeChange = () => {
    introCoverElements.forEach((el) => {
      const element = el;
      const { width, height } = element.parentNode.getBoundingClientRect();
      const wRatio = width / element.dataset.width;
      const hRatio = height / element.dataset.height;
      if (wRatio > hRatio) {
        element.style.width = `${element.dataset.width * wRatio}px`;
        element.style.height = `${el.dataset.height * wRatio}px`;
      } else {
        element.style.width = `${element.dataset.width * hRatio}px`;
        element.style.height = `${element.dataset.height * hRatio}px`;
      }
    });
  };

  if (scene) {
    // eslint-disable-next-line no-unused-vars
    introParallaxInstance = new Parallax(scene, {
      onReady() {
        setTimeout(() => {
          hidePreloader();
          if (introCoverElements.length > 0) {
            introCoverElementsSizeChange();
            window.addEventListener('resize', introCoverElementsSizeChange);
          }
        }, 1000);
      },
    });
  }
}

export default initIntro;
